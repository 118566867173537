import React from "react"

// Libraries
import styled from "styled-components"
import { motion } from "framer-motion"
import { StaticImage } from "gatsby-plugin-image"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import HeroArrow from "components/hero-arrow"
import { colors } from "utils/variables"

const StyledHero = styled.section`
  min-height: calc(100vh - 62px - 58px);
  position: relative;
  display: flex;
  align-items: center;
  padding: 32px 0;
  text-align: center;
  overflow: visible !important;

  ${breakpoint.small`
    min-height: calc(100vh - 96px - 58px);
    text-align: left;
  `}

  .hero__arrow {
    a {
      background-color: ${colors.navy__extra_dark};

      &:hover {
        background-color: ${colors.turquoise__primary};
      }

      svg {
        * {
          stroke: ${colors.white};
        }
      }
    }

    .arrow__illustration {
      * {
        fill: ${colors.white};
      }
    }
  }

  .hero__text {
    max-width: 448px;
    width: 100%;
  }
`

const Hero = () => (
  <StyledHero>
    <Container>
      <div className="row align-items-center">
        <div className="col-12 col-sm-8 col-md-6 mb-4 mb-sm-0">
          <motion.div
            initial={{ y: 32, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            viewport={{ once: true }}
            transition={{
              delay: 0.5,
            }}
            className="hero__content"
          >
            <h1 className="h1 maxi mb-3">
              Discover Talent. Predict Outcomes. Reduce Bias.
            </h1>

            <p className="text--large mb-4">
              At Arena Analytics we use predictive analytics, data science, and
              artificial intelligence to transform the way organizations
              identify, match, and hire people.
            </p>
          </motion.div>
        </div>

        <motion.div
          initial={{ x: 100 }}
          whileInView={{ x: 0 }}
          viewport={{ once: true }}
          className="col-12 col-sm-4 col-md-6"
        >
          {/* <Lottie animation={HeroAnimation} /> */}
          <StaticImage
            className="hero__image mb-2"
            src="../../assets/images/our-platform/hero-image.png"
            alt=""
          />
        </motion.div>
      </div>
    </Container>

    <HeroArrow link="#hire" className="hero__arrow" />
  </StyledHero>
)

export default Hero
