import React from "react"

// Libraries
import styled from "styled-components"

// Layout
import Layout from "layouts/layout-primary"

// Sections
import Hero from "sections/our-platform/hero"
import HireBasedData from "sections/our-platform/hire-based-data"
import Features from "sections/our-platform/features"
import SEO from "components/seo"

const StyledOurPlatform = styled.div`
  max-width: 100vw;
  overflow-x: hidden;

  > section {
    overflow-x: hidden;
  }
`

const OurPlatform = () => (
  <Layout>
    <SEO
      title="Our Platform | Leveraging Data to Transform the Labor Market | Arena Analytics"
      description="Take the guesswork out of hiring with Arena Analytics, an AI-driven hiring and workforce planning solution for large organizations."
    />
    <StyledOurPlatform>
      <Hero />
      <HireBasedData />
      <Features />
    </StyledOurPlatform>
  </Layout>
)

export default OurPlatform
