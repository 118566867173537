import React from "react"

// Libraries
import styled from "styled-components"
import { transparentize } from "polished"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors, fonts } from "utils/variables/"

// Components
import Container from "components/container/"
import Accordion from "components/accordion"
import Button from "components/button"

// Icons
import IconCheck from "assets/icons/our-platform/icon-check.svg"
import IconTalentDiscovery from "assets/icons/our-platform/icon-talent-discovery.inline.svg"
import IconRetentionPrediction from "assets/icons/our-platform/icon-retention-prediction.inline.svg"
import IconBiasMitigation from "assets/icons/our-platform/icon-bias-mitigation.inline.svg"
import IconUp from "assets/icons/our-platform/icon-up.inline.svg"
import IconTurnover from "assets/icons/our-platform/icon-turnover.inline.svg"
import IconSavings from "assets/icons/our-platform/icon-savings.inline.svg"
import IconFile from "assets/icons/icon-file.inline.svg"

const StyledFeatures = styled.section`
  padding-bottom: 60px;

  ${breakpoint.medium`
    padding-bottom: 120px;
  `}

  .feature {
    padding: 60px 0;

    ${breakpoint.medium`
      padding: 120px 0;
    `}

    .feature__image {
      order: 1;

      ${breakpoint.small`
        order: initial;
      `}
    }

    .feature__content {
      order: 0;

      ${breakpoint.small`
        order: initial;
      `}
    }

    ul {
      padding-top: 32px;
      margin: 32px 0;
      border-top: 2px solid ${transparentize(0.9, colors.silver__primary)};

      li {
        display: flex;
        margin-bottom: 24px;

        &::before {
          content: url("${IconCheck}");
          width: 24px;
          height: 24px;
          position: relative;
          top: -4px;
          display: inline-block;
          margin-right: 16px;
        }

        &:last-child {
          margin-bottom: 0;
        }

        p {
          display: inline;
        }
      }
    }

    p {
      max-width: 448px;
    }
  }

  .card {
    padding: 60px 24px;
    border-radius: 16px;
    overflow: hidden;

    ${breakpoint.medium`
      padding: 64px 0 80px 0;
    `}

    &--case-study {
      .result {
        p {
          max-width: 174px;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    .result {
      height: 100%;
      padding: 48px 0;
      margin: 0 48px;
      text-align: center;
      border-bottom: 1px solid ${colors.navy__primary};

      ${breakpoint.small`
        padding: 16px 32px;
        margin: 0 auto;
        border-right: 1px solid ${colors.navy__primary};
        border-bottom: 0;
      `}

      &--no-border {
        border: 0;
      }

      .metric {
        font-family: ${fonts.secondary};
        font-size: 56px;
        line-height: 1.25em;
        font-weight: 700;
      }

      svg {
        flex-shrink: 0;
      }
    }
  }

  .quote {
    padding-bottom: 130px;
    position: relative;

    ${breakpoint.large`
      padding: 64px 0 110px 0;
    `}

    .quote__logo {
      margin-top: -64px;

      ${breakpoint.large`
        position: absolute;
        top: 20px;
        right: 56px;
        margin: 0;
      `}
    }

    .quote__icon {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
`

const Features = () => (
  <StyledFeatures className="bg--silver__pale">
    <Container>
      {/* Talent Discovery:start */}
      <div id="talentDiscovery" className="feature">
        <div className="row justify-content-between">
          <div className="feature__image col-12 col-sm-6 mb-4 mb-sm-0 d-flex">
            <IconTalentDiscovery />
          </div>

          <div className="feature__content col-12 col-sm-6">
            <h3 className="h2 maxi mb-2">Talent Discovery</h3>
            <p className="mb-2 text--large">
              Our Talent Discovery solution provides a more efficient applicant
              flow so that companies can hire more of the candidates they
              receive and spend less time and energy on each hire.
            </p>
            <Accordion
              content={
                <p>
                  How do we do it? Using predictive data modeling we determine
                  where to focus sourcing and recruitment marketing strategies,
                  while supplying additional qualified candidates who are likely
                  to be retained where companies need it the most. We also
                  suggest additional positions a candidate may be suited for,
                  and allow candidates to apply to multiple jobs within an
                  organization.
                </p>
              }
            />

            <ul>
              <li>
                <p className="font-weight--600">
                  Increase high-quality candidate flow
                </p>
              </li>
              <li>
                <p className="font-weight--600">
                  Match candidates to multiple jobs
                </p>
              </li>
              <li>
                <p className="font-weight--600">
                  Rediscover candidates for new jobs
                </p>
              </li>
            </ul>
            <Button
              type="link"
              level="secondary"
              to="/how-it-works#talentDiscovery"
            >
              See how it works
            </Button>
          </div>
        </div>
      </div>
      {/* Talent Discovery:end */}

      {/* Results:start */}
      <div className="card bg--navy__extra_dark color--white text-center">
        <p className="h3 font-weight--700 mb-sm-4 mb-md-5">Arena Results</p>

        <div className="row justify-content-center">
          <div className="col-12 col-sm-11">
            <div className="row">
              <div className="col-12 col-sm-4 p-sm-0">
                <div className="result">
                  <span className="metric d-flex align-items-center justify-content-center mb-3">
                    15
                    <span className="color--turquoise__primary">%</span>
                    <IconUp className="ms-2" />
                  </span>

                  <p className="color--silver__pale">
                    Arena boosts volume of quality candidates by up to 15%
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4 p-sm-0">
                <div className="result">
                  <span className="metric d-flex align-items-center justify-content-center mb-3">
                    18
                    <span className="color--turquoise__primary">%</span>
                    <IconUp className="ms-2" />
                  </span>
                  <p className="color--silver__pale">
                    Arena candidates submit 18% more applications than other
                    candidate sources
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4 p-sm-0">
                <div className="result result--no-border">
                  <span className="metric d-flex align-items-center justify-content-center mb-3">
                    16
                    <span className="color--turquoise__primary">%</span>
                    <IconUp className="ms-2" />
                  </span>

                  <p className="color--silver__pale">
                    16% of Arena candidates are hired for a job for which they
                    did not apply
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Results:end */}

      {/* Retention Prediction:start */}
      <div id="retentionPrediction" className="feature">
        <div className="row justify-content-between">
          <div className="feature__content col-12 col-sm-6">
            <h3 className="h2 maxi mb-2">Retention Prediction</h3>
            <p className="mb-2 text--large">
              Our Retention Prediction solution helps organizations drastically
              reduce their premium labor costs by reducing turnover.
            </p>
            <Accordion
              title="Read More"
              content={
                <p>
                  How do we do it? We identify job candidates who are most
                  likely to be retained in a specific organization and role
                  before they are hired. By increasing the tenure of new hires,
                  we thereby help organizations reduce wasteful turnover
                  spending, including overtime and contract labor, talent
                  sourcing, recruiting, onboarding, and training costs.
                  <br />
                  <br />
                  With a drop in first-year turnover resulting in a more stable
                  workforce, customers also report lower instances of staff
                  burnout and higher rates of employee engagement and customer
                  satisfaction.
                </p>
              }
            />

            <ul>
              <li>
                <p className="font-weight--600">
                  Reduce turnover and improve retention
                </p>
              </li>
              <li>
                <p className="font-weight--600">
                  Match the right candidates to the right jobs
                </p>
              </li>
              <li>
                <p className="font-weight--600">Improve workforce stability</p>
              </li>
            </ul>
            <Button
              type="link"
              level="secondary"
              to="/how-it-works#retentionPrediction"
            >
              See how it works
            </Button>
          </div>

          <div className="feature__image col-12 col-sm-6 d-flex">
            <IconRetentionPrediction />
          </div>
        </div>
      </div>
      {/* Retention Prediction:end */}

      {/* Case Study:start */}
      <div className="card card--case-study bg--navy__extra_dark color--white">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-11 col-md-10">
            <p className="h3 text-center font-weight--700 mb-sm-4 mb-md-5">
              Our Impact
            </p>

            <div className="row">
              <div className="col-12 col-sm-4 p-sm-0">
                <div className="result">
                  <span className="metric d-flex align-items-center justify-content-center mb-3">
                    28
                    <span className="color--turquoise__primary">%</span>
                    <IconTurnover className="ms-2" />
                  </span>

                  <p className="color--silver__pale">
                    Turnover reduction in first 60 days
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4 p-sm-0">
                <div className="result result--no-border">
                  <span className="metric d-flex align-items-center justify-content-center mb-3">
                    $1.32
                    <span className="color--turquoise__primary">M</span>
                    <IconSavings className="ms-2" />
                  </span>
                  <p className="color--silver__pale">
                    Savings from hiring Arena candidates
                  </p>
                </div>
              </div>

              <div className="col-12 col-sm-4 p-sm-0">
                <div className="result result--no-border d-flex align-items-center justify-content-center">
                  <Button
                    type="link"
                    level="tertiary"
                    external
                    to="/case-studies/an-innovative-approach-to-boosting-retention.pdf"
                  >
                    Open case study
                    <IconFile className="ms-2" />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Case Study:end */}

      {/* Bias Mitigation:start */}
      <div id="biasMitigation" className="feature">
        <div className="row justify-content-between">
          <div className="feature__image col-12 col-sm-6 d-flex">
            <IconBiasMitigation />
          </div>

          <div className="feature__content col-12 col-sm-6 col-md-5">
            <h3 className="h2 maxi mb-2">Bias Mitigation</h3>
            <p className="mb-2 text--large">
              Our bias mitigation solution helps create equitable workforces
              that more closely match the demographics of the communities they
              serve.
            </p>
            <Accordion
              title="Read More"
              content={
                <p>
                  How do we do it? The Arena Predictive Analytics Platform
                  employs a unique machine learning technique known as
                  adversarial fairness to mitigate bias while predicting
                  retention outcomes.
                  <br />
                  <br />
                  The adversarial fairness model looks at input data from each
                  applicant and attempts to determine characteristics like
                  gender and race. If the model is successful at predicting
                  those factors, it then isolates the specific parts of the
                  input data related to gender and race and strips them from the
                  overall model, removing bias from the retention prediction.
                </p>
              }
            />

            <ul>
              <li>
                <p className="font-weight--600">
                  Reduce bias in the hiring process
                </p>
              </li>
              <li>
                <p className="font-weight--600">
                  Increase equity and diversity
                </p>
              </li>
              <li>
                <p className="font-weight--600">Improve workforce stability</p>
              </li>
            </ul>
            <Button
              type="link"
              level="secondary"
              to="/how-it-works#biasMitigation"
            >
              See how it works
            </Button>
          </div>
        </div>
      </div>
      {/* Bias Mitigation:end */}
    </Container>
  </StyledFeatures>
)

export default Features
