import React, { useState } from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"

// Icons
import IconCaret from "assets/icons/icon-caret-down.inline.svg"
import { fonts } from "utils/variables"

const StyledAccordion = styled.div`
  .accordion__toggler {
    font-family: ${fonts.secondary};
    font-size: 16px;
    line-height: 1.25em;

    svg {
      width: 8px;
      height: auto;
      transform: rotate(${(props) => (props.active ? "180deg" : "0")});
      transition: all 0.6s ease;
    }
  }

  .accordion__content {
    max-height: ${(props) => (props.active ? "500px" : "0")};
    font-size: 20px;
    line-height: 28px;
    transition: max-height 0.6s ease;
    overflow: hidden;
  }
`

const Accordion = (props) => {
  const { content } = props

  // State
  const [active, setActive] = useState(false)

  return (
    <StyledAccordion active={active}>
      <div className="accordion__content mb-2">{content}</div>

      <button
        type="button"
        className="accordion__toggler d-flex align-items-center color--turquoise__primary color-hover--siver__primary font-weight--700 svg--stroke-silver__primary"
        onClick={() => setActive(!active)}
      >
        {!active ? "Read More" : "Read Less"}

        <IconCaret className="ms-2" />
      </button>
    </StyledAccordion>
  )
}

Accordion.propTypes = {
  content: PropTypes.element.isRequired,
}

export default Accordion
