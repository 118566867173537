import React from "react"

// Libraries
import styled from "styled-components"

// Components
import Container from "components/container"
import { StaticImage } from "gatsby-plugin-image"

// Utils
import { colors } from "utils/variables"
import breakpoints from "utils/breakpoints"

const StyledHireBasedData = styled.section`
  background: ${colors.navy__extra_dark};
  padding: 120px 20px 80px 20px;
  color: white;
  ${breakpoints.medium`
    padding: 120px 0 80px 0;
  `}

  .data {
    max-width: 447px;
    width: 100%;
  }
`

const HireBasedData = () => (
  <StyledHireBasedData id="hire">
    <Container>
      <div className="row align-items-center justify-content-md-between">
        <div className="col-12 col-md-5">
          <h2 className="h2 maxi">Hire Based on Data That Matters</h2>
          <br />
          <p className="data text--large color--silver__light">
            Building a stable, equitable workforce can feel like an impossible
            task and many of the “AI” solutions out there rely on bias-filled,
            incomplete datasets.
            <br />
            <br />
            In contrast, our platform uses sophisticated predictive analytics to
            objectively analyze data and get you the insights that really
            matter: Which candidates are most likely to stay at your company if
            hired.
          </p>
          <br />
        </div>

        <div className="col-12 col-md-6">
          <StaticImage
            src="../../assets/images/our-platform/hire-based-on-data-image.png"
            alt=" "
            width={560}
            height={413}
          />
        </div>
      </div>
    </Container>
  </StyledHireBasedData>
)

export default HireBasedData
